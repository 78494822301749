import { Title } from '@mantine/core'
import React from 'react'
import classes from './SectionHeader.module.css'

const SectionHeader = ({ title, subTitle }) => {
    return (
        <div>
            {subTitle ? <Title data-aos="fade-up-right" size={'h3'} className={classes.subTitle}>{subTitle}</Title> : null}
            <Title data-aos="fade-up-left" size={{ xl: 'h2' }} c="var(--secondary-color)" className={classes.title}>{title}</Title>
        </div>
    )
}

export default SectionHeader