import { Container, Grid, GridCol, Image, Text } from '@mantine/core'
import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { LayoutContext } from '../../context'
import SectionHeader from '../SectionHeader'
import SectionLoader from '../SectionLoader'

const Blogs = () => {
    const { dataList } = useContext(LayoutContext)

    return (
        <div data-aos="fade-up">
            {
                dataList?.renoking_blogs ?
                    <section className='h-full section-light'>
                        <Container>
                            <SectionHeader title="Blog and Articles" subTitle="Latest updates" />
                            <Grid mt='xl'>
                                {
                                    dataList?.renoking_blogs?.data.map(item => (
                                        <GridCol component={Link} to={`/blogs/${item.slug}`} span={{ lg: 4, md: 6, base: 12 }} key={item._id} p={{ xl: 'xl' }}>
                                            <Image src={item.image.url} width="100%" />
                                            <Text mb='sm' mt='lg' ta={'justify'} fw={'bold'}>{item.title.substring(0, 80)}...</Text>
                                            <Text size='sm' ta={'justify'}>{item.description.substring(0, 250)}...</Text>
                                        </GridCol>
                                    ))
                                }
                            </Grid>
                        </Container>
                    </section>
                    :
                    <SectionLoader />
            }
        </div>
    )

}

export default Blogs