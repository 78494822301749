import { Skeleton } from '@mantine/core';

function SectionLoader() {
    return (
        <Skeleton visible={true} mih={'90vh'}>
        </Skeleton>
    );
}

export default SectionLoader;
