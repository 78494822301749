import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { Link } from 'react-router-dom';
import { getAllService } from '../../services';
import styles from './OtherService.module.css';

const OtherService = ({ slug }) => {
    const { data, isFetching, isLoading } = useQuery({
        queryKey: ['services'], queryFn: getAllService
    })
    if (isFetching && isLoading) return 'Loading...'
    return (
        <div>
            <hr className='my-5' />
            <h5 className='section_title text-danger mb-4'>OtherService</h5>
            <div className="row">
                {
                    data.data.data.map((item) => slug != item.slug && (
                        <div className="col-md-6 col-lg-4" key={item._id}>
                            <Link to={`/services/${item.slug}`} className={styles.card}>
                                <img src={item.image.url} className={styles.cardImage} />
                                <div className={styles.cardBody + " shadow rounded"}>
                                    <h4 className={styles.cardTitle + " section_title text-danger"}>{item.name}</h4>
                                    <p className={styles.cardContent}>{item.description.substring(0, 100)}</p>
                                </div>
                            </Link>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default OtherService