import { MantineProvider } from '@mantine/core';
import '@mantine/core/styles.css';
import 'animate.css';
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import 'swiper/css/navigation';
import Layout from './Layout';
import { theme } from './configs/theme';
import BlogView from './pages/BlogView';
import Blogs from './pages/Blogs';
import Home from './pages/Home';
import Services from './pages/Services';

const App = () => {
    return (
        <MantineProvider theme={theme} forceColorScheme="light">
            <BrowserRouter>
                <Routes>
                    <Route path='/' Component={Layout}>
                        <Route index Component={Home} />
                        <Route path='/blogs' Component={Blogs} />
                        <Route path='/blogs/:slug' Component={BlogView} />
                        <Route path='/services/:slug' Component={Services} />
                    </Route>
                </Routes>
            </BrowserRouter>
        </MantineProvider>
    )
}

export default App