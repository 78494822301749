import React from 'react'
import AboutUs from '../components/AboutUs'
import Banners from '../components/Banners'
import Blogs from '../components/Blogs'
import Process from '../components/Process'
import Services from '../components/Services'
import Testimonials from '../components/Testimonials'
import { HomeApiProvider } from '../providers/HomeApiProvider'

const Home = () => {
    return (
        <HomeApiProvider>
            <Banners />
            <AboutUs />
            <Services />
            <Process />
            <Blogs />
            <Testimonials />
        </HomeApiProvider>
    )
}

export default Home