import { generateColors } from "@mantine/colors-generator";
import { Container, createTheme, rem } from "@mantine/core";

export const theme = createTheme({
    fontFamily: 'Poppins',
    headings: {
        fontFamily: 'Anton',
        fontWeight: 400,
        sizes: {
            h1: {
                fontSize: rem(94),
            },
            h2: {
                fontSize: rem(40),
            },
            h3: {
                fontSize: rem(26),
            },
        },
    },
    fontSizes: {
        sm: rem(13),
        md: rem(15),
        lg: rem(18),
        xl: rem(24),
    },
    primaryColor: 'primary',
    colors: {
        'primary': generateColors('#480900')
    },
    components: {
        Container: Container.extend({
            vars: (_, { size, fluid }) => ({
                root: {
                    '--container-size': fluid ? '100%' : size === undefined ? rem(1400) : rem(size),
                },
            }),
        }),
    }
})
