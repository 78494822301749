import { Box, Container, Flex, Grid, Text } from '@mantine/core';
import React from 'react';
import { Link } from 'react-router-dom';
import ContactForm from '../ContactForm';
import style from './Footer.module.css';
function Footer() {
    return (
        <footer className={style.footer} id="contact">
            <Container pb={'xl'}>
                <Grid gutter="md" justify='space-between'>
                    <Grid.Col span={{ base: 12, lg: 4 }} ta={{ md: 'left', base: 'center' }}>
                        <div data-aos="fade-up">
                            <p className={style.title}><b>RENOKING</b></p>
                            <p className={style.description}>Panchatcharam is the Managing Director of Gnanam Engineering and Gnanam Homes and Estate in India Panchatcharam has 20 years of experience, serving in several leadership and customer service roles He was associated with L&T, BGR, Ramky, and ITD, both industrial and residential Property</p>
                        </div>
                        <Box mt={'xl'} fw={'lighter'}>
                            <Text fw={'bold'} mb={'sm'}>Support: Consultation [or] enquires</Text>
                            <div>Call: <a className={style.link} href="tel:919940256161">+91-99402 56161</a></div>
                            <div>Mail: <a className={style.link} href="mailto:service@renoking.in">service@renoking.in </a></div>
                        </Box>
                    </Grid.Col>
                    <Grid.Col span={{ base: 12, lg: 2 }} ta={{ md: 'left', base: 'center' }}>
                        <div data-aos="fade-up">
                            <h3>Quick Links</h3>
                            <ul className={style.links}>
                                <li><Link to='/'>Home</Link></li>
                                <li><Link to='/'>About us</Link></li>
                                <li><Link to='/'>Services</Link></li>
                                <li><Link to='/'>Plots</Link></li>
                                <li><Link to='/'>Villas</Link></li>
                                <li><Link to='/'>Apartment</Link></li>
                                <li><Link to='/'>Farm House</Link></li>
                            </ul>
                        </div>
                    </Grid.Col>
                    <Grid.Col span={{ base: 12, lg: 2 }} ta={{ md: 'left', base: 'center' }}>
                        <div data-aos="fade-up">
                            <Box visibleFrom='lg'><h3 style={{ opacity: 0 }}>Quick Links</h3></Box>
                            <ul className={style.links}>
                                <li><Link to='/'>Project</Link></li>
                                <li><Link to='/'>Upcoming project</Link></li>
                                <li><Link to='/'>Ongoing project</Link></li>
                                <li><Link to='/'>Complete projects</Link></li>
                                <li><Link to='/'>Contact us</Link></li>
                            </ul>
                        </div>
                    </Grid.Col>
                    <Grid.Col span={{ base: 12, lg: 4 }} ta={{ md: 'right', base: 'center' }}>
                        <div data-aos="fade-down">
                            <Text size='lg' fw={500}>Just say Hello</Text>
                            <Text size='sm' fw={'lighter'}>Let us know more about you</Text>
                            <ContactForm />
                        </div>
                    </Grid.Col>
                </Grid>
            </Container>
            <div className={style.bgPrimary}>
                <Container ta={{ md: 'left', base: 'center' }}>
                    <Flex direction={{ md: 'row', base: 'column' }} justify={{ md: 'space-between', base: 'center' }}>
                        <div>Copyright @2024 ejnarstudios | All rights reserved by renoking | privacy policy | Terms of use | Legal</div>
                        <div>India</div>
                    </Flex>
                </Container>
            </div>
        </footer>
    );
}

export default Footer;