import { Box, Text, Title } from '@mantine/core';
import React, { useContext } from 'react';
import { MdLocationOn } from "react-icons/md";
import { Link } from 'react-router-dom';
import { Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { LayoutContext } from '../../context';
import SectionHeader from '../SectionHeader';
import SectionLoader from '../SectionLoader';
import classes from './Services.module.css';

const slidesPerView = {
    640: {
        slidesPerView: 2,
        spaceBetween: 10,
    },
    768: {
        slidesPerView: 3,
    },
    992: {
        slidesPerView: 2,
    },
    1024: {
        slidesPerView: 3,
    },
    1440: {
        slidesPerView: 4,
    }
};
const Services = () => {
    const { dataList } = useContext(LayoutContext)

    return (
        <div data-aos="flip-right" id='services'>
            {
                dataList?.renoking_services?.data ? <section className='serviceSlider' >
                    <Box p={'md'} >
                        <div className={classes.slider}>
                            <Box mb={'xl'}>
                                <SectionHeader title="Our Services" subTitle="PORTFOLIO" />
                            </Box>
                            <div >
                                <Swiper
                                    spaceBetween={30}
                                    loop={true}
                                    pagination={{ clickable: true }}
                                    breakpoints={slidesPerView}
                                    modules={[Autoplay]}
                                    autoplay={{
                                        delay: 2500,
                                        disableOnInteraction: false,
                                    }}
                                >
                                    {
                                        dataList.renoking_services.data.map(item => (
                                            <SwiperSlide key={item._id}>
                                                <Link to={`/services/${item?.slug}`} className={classes.card} >
                                                    <img src={item?.image ? item.image.url : require('./../../images/banner-bg.png')} className={classes.image} />
                                                    <div className={classes.content}>
                                                        <Title size='h6' c="lightgray" className='text-shadow'>SERVICES</Title>
                                                        <Title size='h2' c="white" className='text-shadow'>{item.name}</Title>
                                                        <Text mt={'md'} size='lg' c="var(--secondary-color)" className='text-shadow' display={'flex'} ><MdLocationOn size={22} /><Text display={'inline'} ml={'xs'}>{item.location}</Text></Text>
                                                    </div>
                                                </Link>
                                            </SwiperSlide>
                                        ))
                                    }
                                </Swiper>
                            </div>
                        </div>
                    </Box>
                </section>
                    : <SectionLoader />
            }
        </div>
    )

}

export default Services