import axios from 'axios';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { FaArrowRightLong } from 'react-icons/fa6';
import Swal from 'sweetalert2';
import style from '../../components/Footer/Footer.module.css';


const ContactForm = () => {
    const [loading, setLoading] = useState(false)
    const { register, handleSubmit, formState: { errors }, reset } = useForm();

    const onSubmit = (data) => {
        setLoading(true)
        axios.post('https://ejnarstudios.com/ej-mailer/api/send-mail', data).then(response => {
            if (response.data.status) {
                Swal.fire({
                    title: 'Thank you!',
                    html: `<h4 className="mb-3">for reaching out to us! we will contact you soon.</h4>`,
                    confirmButtonColor: '#480900',
                    confirmButtonText: 'Okay',
                })
            } else {
                Swal.fire({
                    title: 'Whoo!',
                    text: response.data.message,
                    icon: 'warning',
                    confirmButtonText: 'Okay',
                    confirmButtonColor: '#480900'
                })
            }
        }).finally(() => {
            reset()
            setLoading(false)
        })
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <input type="text" className={`mb-3 ${style.input} ${errors?.name ? 'is-invalid' : ''}`} placeholder='Enter your name' {...register('name', { required: true })} />
            <input type="number" className={`mb-3 ${style.input} ${errors?.mobile ? 'is-invalid' : ''}`} placeholder='Enter your contact no' {...register('mobile', { required: true })} />
            <input type="text" className={`mb-3 ${style.input} ${errors?.email ? 'is-invalid' : ''}`} placeholder='Enter your email' {...register('email', { required: true, pattern: /^\S+@\S+$/i })} />
            <textarea className={`${style.input} ${errors?.message ? 'is-invalid' : ''}`} placeholder='Message' {...register('message', { required: true })}></textarea>
            <button type="submit" disabled={loading} className={style.submit}>{loading ? 'SUBMITTING ...' : 'SUBMIT'} <FaArrowRightLong className='ms-2' /> </button>
        </form>
    );
};

export default ContactForm;
