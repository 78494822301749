import { Box, Container, Title } from '@mantine/core'
import { useToggle } from '@mantine/hooks'
import React, { useContext, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { FaTimes } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import Slider from 'react-slick'
import YouTube from 'react-youtube'
import { LayoutContext } from '../../context'
import SectionHeader from '../SectionHeader'
import classes from './Process.module.css'


function extractVideoId(url) {
    var pattern = /(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|shorts\/|watch\?v=|watch\?.+&v=))([^&?\/]+)/;
    var match = url.match(pattern);
    if (match) {
        return match[1];
    }
    return null;
}

function Process() {
    const { dataList } = useContext(LayoutContext)
    const [url, setUrl] = useState('')
    const [isOpen, toggle] = useToggle([false, true])
    const image = window.innerWidth > 992 ? "process-lg.png" : "process-sm.png";
    const settings = {
        className: 'video-slider',
        dots: true,
        infinite: true,
        centerMode: window.innerWidth > 992,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: window.innerWidth > 992,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 2000
    };
    return (
        <>
            <Container mb={'xl'}>
                <SectionHeader title="The process how we work on" subTitle="How it works" />
            </Container>
            <Box bg="var(--primary-color)" className='section' data-aos="fade-up">
                <Container pos={'relative'}>
                    <img src={require(`../../images/${image}`)} width='100%' />
                    <Link className={classes.actionButton}>BOOK FREE CONSULTATION</Link>
                </Container>
            </Box>
            {
                dataList?.renoking_videos ?
                    <div data-aos="fade-up">
                        <Slider {...settings} >
                            {dataList.renoking_videos.data.map(item => (
                                <div className={classes.videoCard} key={item._id}>
                                    <div className={classes.content}>
                                        <div>
                                            <Title size="h2" c="white">{item.title}</Title>
                                            <Title size="h2" c="var(--secondary-color)">{item.sub_title}</Title>
                                        </div>
                                        <div>
                                            <img src={require('../../images/play-button.png')} onClick={() => {
                                                toggle();
                                                setUrl(item.url)
                                            }} className={classes.playButton} width={'100%'} />
                                        </div>
                                    </div>
                                    <img src={item.image.url} className={classes.slideImage} />
                                </div>
                            ))}
                        </Slider>
                    </div>
                    : null
            }


            <Modal
                size="lg"
                show={isOpen}
                onHide={toggle}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Body className='position-relative'>
                    <button className='btn-close mb-3 float-end' onClick={toggle}><FaTimes /></button>
                    <YouTube videoId={extractVideoId(url)} opts={{
                        height: '390',
                        width: '100%',
                        playerVars: {
                            autoplay: 1,
                        },
                    }} />
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Process