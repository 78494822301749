import { Container, Grid, GridCol, Image, Text } from '@mantine/core';
import React from 'react';
import SectionHeader from '../SectionHeader';
import classes from './AboutUs.module.css';

const AboutUs = () => {
    return (
        <section id='about-us'>
            <Container>
                <Grid align='center'>
                    <GridCol span={{ lg: 6, base: 12 }} data-aos="fade-up">
                        <Image src={require('../../images/about-us.png')} className={classes.aboutImage} />
                    </GridCol>
                    <GridCol span={{ lg: 6, base: 12 }} data-aos="fade-down">
                        <SectionHeader title="Your Trusted renovation Company" subTitle="Get To know" />

                        <Text mt='lg' size={{ 'xl': 'xl' }} ta={'justify'}> Reno King started with the vision of solving customer issues by renovating their houses and commercial spaces.
                            Reno King handles regular renovations to specialized works, from masonry to complete interiors, all small to large areas of work.
                            Engineers, designers, architects, and a qualified workforce equip Reno King with complete expertise to ensure customer delight.
                            Reno King is a group that Gnanam Engineering started to handle, especially renovation work.</Text>

                    </GridCol>
                </Grid>
            </Container>
        </section>
    )
}

export default AboutUs