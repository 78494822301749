import { Burger, Container, Drawer, Flex, Group, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import React from 'react';
import { BiLogoFacebook, BiLogoInstagramAlt, BiLogoWhatsapp, BiLogoYoutube } from "react-icons/bi";
import { Link, NavLink } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import Image from '../Image';
import ServiceDropDown from '../ServiceDropDown';
import classes from './Header.module.css';


const Header = () => {
  const [opened, { toggle }] = useDisclosure(false);

  const navLinks = () => {
    return (
      <>
        <NavLink to={'/'} className={'link'}>Home</NavLink>
        <HashLink to={'/#about-us'} className={'link'}>About us</HashLink>
        <ServiceDropDown />
        <NavLink to={'/blogs'} className={'link'}>Blogs</NavLink>
        <HashLink to={'#contact'} className={'link'}>Contact Us</HashLink>
      </>
    )

  }
  return (
    <>
      <header className={classes.header + 'animate__animated animate__fadeIn shadow'}>
        <div className={classes['inner-header']}>
          <Container>
            <Flex justify={{ md: 'space-between', base: 'center' }} align={'center'} gap={'md'} direction={{ md: 'row', base: 'column' }}>
              <Flex gap={'md'}>
                <a className={classes['header-link']} target='_blank' href="https://www.instagram.com/renoking?igsh=NXFoNTBjd3NnajQ3"><BiLogoInstagramAlt /></a>
                <a className={classes['header-link']} target='_blank' href='https://www.facebook.com/profile.php?id=61557617276909'><BiLogoFacebook /></a>
                <a className={classes['header-link']} target='_blank' href='https://wa.me/+919940256161'><BiLogoWhatsapp /></a>
                <a className={classes['header-link']} target='_blank' href='https://youtube.com/@RenoKing-lc3ws?si=oWS4XK5Xz4E_F29H'><BiLogoYoutube /></a>
              </Flex>
              <Text ta={{ base: 'center', lg: 'left' }}>
                Call: <a href="tel:919940256161">+91 99402 56161</a> | Mail: <a href="mailto:service@renoking.in">service@renoking.in </a>
              </Text>
            </Flex>
          </Container>
        </div>
        <Container className={classes.inner}>
          <Link to='/'><Image src={require('../../images/logo.png')} width={150} /></Link>
          <Group gap={5} visibleFrom="sm">
            {navLinks()}
          </Group>
          <Burger opened={opened} onClick={toggle} hiddenFrom="sm" size="md" />
        </Container>
      </header>
      <Drawer position='right' radius="md" opened={opened} onClose={toggle} title="">
        {navLinks()}
      </Drawer>
    </>
  )
}

export default Header