import { Button, Container, Text, Title } from '@mantine/core';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { LayoutContext } from '../../context';
import SectionLoader from '../SectionLoader';
import classes from './Banners.module.css';

const Banners = () => {
    const { dataList } = useContext(LayoutContext)

    if (dataList?.renoking_banners) {
        return (
            <Swiper
                spaceBetween={50}
                slidesPerView={1}
                autoplay={{
                    delay: 3000,
                    disableOnInteraction: false,
                }}
                modules={[Autoplay]}
            >
                {dataList?.renoking_banners.data.length ? dataList?.renoking_banners.data.map(item => (
                    <SwiperSlide key={item._id}>
                        <div className={classes.bannerSlide}>
                            <Container>
                                <div className={classes.bannerContent}>
                                    <Title className='animate__animated animate__backInDown' size='h3' tt="uppercase">{item.sub_title}</Title>
                                    <Title className='animate__animated animate__bounce' size={window.innerWidth < 992 ? 'h2' : 'h1'} w={{ lg: '50%' }} c="var(--secondary-color)">{item.title}</Title>
                                    <Text className='animate__animated animate__fadeIn' w={{ lg: '45%' }}>{item.description}</Text>
                                    <Button className='animate__animated animate__backInUp' c="black" radius={0} size='md' mt={'md'} bg="var(--secondary-color)" component={Link}>READ MORE</Button>
                                </div>
                            </Container>
                            <div className={classes.overlay}></div>
                        </div>
                        <img src={item.image.url} className={classes.bannerImage} />
                    </SwiperSlide >
                )) : null}
            </Swiper >
        )
    }
    return <SectionLoader />
}

export default Banners