import {
    QueryClient,
    QueryClientProvider
} from '@tanstack/react-query';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { Outlet } from 'react-router-dom';
import 'swiper/css';
import Footer from './components/Footer';
import Header from './components/Header';

const queryClient = new QueryClient()

const Layout = () => {

    Aos.init({
        disable: 'mobile'
    })

    return (
        <QueryClientProvider client={queryClient}>
            <Header />
            <Outlet />
            <Footer />
        </QueryClientProvider>
    )
}

export default Layout