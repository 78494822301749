import axios from "axios";
import { baseUrl } from "../configs/apiOptions";

export const getAllBlogs = async () => {
    return await axios.post(baseUrl, {
        moduleName: "renoking_blogs",
        limit: 14,
        sorting: [
            {
                desc: -1,
                id: "_id"
            }
        ]
    });
}


export const getBlogBySlug = async (slug) => {
    return await axios.post(baseUrl, {
        moduleName: "renoking_blogs",
        limit: 14,
        filters: [
            {
                value: slug,
                id: "slug"
            }
        ]
    });
}

export const getServiceBySlug = async (slug) => {
    return await axios.post(baseUrl, {
        moduleName: "renoking_services",
        limit: 14,
        filters: [
            {
                value: slug,
                id: "slug"
            }
        ]
    });
}

export const getAllService = async (slug) => {
    return await axios.post(baseUrl, {
        moduleName: "renoking_services",
    });
}


export const getRelatedBlogs = async (slug) => {
    return await axios.post(baseUrl, {
        moduleName: "gnanam_homes_blogs",
        limit: 14,
        filters: [
            {
                value: slug.replaceAll('-', ' '),
                id: "slug"
            },
            {
                value: slug.replaceAll('-', ' '),
                id: "title"
            },
            {
                value: slug.replaceAll('-', ' '),
                id: "description"
            }
        ]
    })
}



