import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const Image = ({ src, alt, ...otherProps }) => {
    return (
        <LazyLoadImage
            src={src}
            alt={alt}
            effect="blur"
            {...otherProps}
        />
    );
};

export default Image;
