import { Box, Container, Flex, Grid, GridCol, Image, Text } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import React, { useContext, useEffect, useState } from 'react'
import Slider from 'react-slick'
import { LayoutContext } from '../../context'
import SectionHeader from '../SectionHeader'
import SectionLoader from '../SectionLoader'

const Testimonials = () => {
    const { dataList } = useContext(LayoutContext)
    const isDesktop = useMediaQuery('(min-width: 768px)');

    return (
        <div data-aos="fade-up">
            {dataList?.renoking_testimonials?.data.length ?
                <section className={`${isDesktop ? 'h-full' : ''}`}>
                    <Box w={'100%'}>
                        <Container>
                            <SectionHeader title="Our Clients Kind words" subTitle="What they say" />
                            <Reviews data={dataList?.renoking_testimonials?.data} />
                        </Container>
                    </Box>
                </section>
                : <SectionLoader />
            }
        </div>
    )


}


const Reviews = ({ data }) => {
    const isDesktop = useMediaQuery('(min-width: 768px)');
    const [active, setActive] = useState(data[0]);

    // Autoplay functionality
    useEffect(() => {
        const interval = setInterval(() => {
            const currentIndex = data.findIndex(item => item._id === active._id);
            const nextIndex = (currentIndex + 1) % data.length;
            setActive(data[nextIndex]);
        }, 3000);

        return () => clearInterval(interval);
    }, [active, data]);
    if (isDesktop) {
        return (
            <Grid mt='xl'>
                <GridCol span={{ lg: 4, base: 12 }}>
                    {
                        data.map(item => (
                            <Box p={'md'} key={item._id} onClick={() => setActive(item)} className={active?._id === item._id ? 'activeSlide' : ''}>
                                <Flex gap={'md'} align={'center'}>
                                    <Image src={item?.image ? item?.image.url : require('../../images/user (1).png')} />
                                    <div>
                                        <Text fw={'bold'} size='lg'>{item.title}</Text>
                                        <Text>{item.sub_title}</Text>
                                    </div>
                                </Flex>
                            </Box>
                        ))
                    }
                </GridCol>
                <GridCol span={1}>
                    <hr className='vr' />
                </GridCol>
                {
                    active ?
                        <GridCol span="auto" p={'xl'}>
                            <center>
                                <Image src={active?.image ? active?.image.url : require('../../images/user (1).png')} w={100} mb={'xl'} />
                                <Text fw={'bold'} size='lg' mt={'md'}>{active?.title}</Text>
                                <Text>{active?.review}</Text>
                            </center>
                        </GridCol>
                        : null
                }
            </Grid>
        )
    } else {
        const settings = {
            dots: true,
            arrows: false,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 2000
        };
        return (
            <Slider {...settings}>
                {
                    data.map((item, index) => (
                        <Box p={'md'} key={index} ta={'center'}>
                            <div>
                                <center><img style={{ border: '2px solid var(--secondary-color)', borderRadius: 100 }} src={item?.image ? item?.image.url : require('../../images/user (1).png')} width="70px" /></center>
                                <Text fw={'bold'} size='lg'>{item.title}</Text>
                                <Text mb='md'>{item.sub_title}</Text>
                                <Text>{item?.review}</Text>
                            </div>
                        </Box>
                    ))
                }
            </Slider>
        )
    }
}

export default Testimonials