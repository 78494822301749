import { Menu } from '@mantine/core';
import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { HashLink } from 'react-router-hash-link';
import { getAllService } from '../../services';
const ServiceDropDown = () => {
    const { data, isSuccess } = useQuery({
        queryKey: ['services'], queryFn: getAllService
    })
    return (
        <div>
            <Menu
                shadow="md"
                width={230}
                trigger="click-hover"
                loop={false}
                withinPortal={false}
                trapFocus={false}
                menuItemTabIndex={0}>
                <Menu.Target>
                    <HashLink to={'/#services'} className={'link'}>Services</HashLink>
                </Menu.Target>
                {
                    isSuccess ?
                        <Menu.Dropdown>
                            {
                                data.data.data.map((item) => (
                                    <Menu.Label key={item._id}>
                                        <HashLink to={`/services/${item.slug}`} >{item.name}</HashLink>
                                    </Menu.Label>
                                ))
                            }
                        </Menu.Dropdown>
                        : null
                }
            </Menu>
        </div>
    )
}

export default ServiceDropDown